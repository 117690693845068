var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMediaQuery } from '@react-hooks-library/core';
import { useCallback, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext, Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { MobileModalForm } from '..';
import { validateField } from '../components/validation';
import { iconSelector } from '../icons/iconSelector';
const CustomMenuList = (_a) => {
    var { children, handleSelect } = _a, props = __rest(_a, ["children", "handleSelect"]);
    if (Array.isArray(children)) {
        const arr = children;
        return (_jsx(components.MenuList, Object.assign({}, props, { className: 'options-wrapper', children: arr.map(child => (
            // Выпили тег option так как его стилизация отрабатывала криво в Safari
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
            _jsx("div", { className: child.props.isSelected ? 'time-option time-option-selected' : 'time-option', onClick: () => {
                    child.props.setValue(child.props.value);
                    handleSelect(child.props.value);
                }, children: child.props.label }, child.props.value))) })));
    }
    return null;
};
const MobileMenu = (_a) => {
    var { label, handleClose, isMobile, children } = _a, props = __rest(_a, ["label", "handleClose", "isMobile", "children"]);
    if (isMobile) {
        return (_jsx(MobileModalForm, { label: label || 'Выберите значение из списка', onClose: handleClose, labelBeforeContent: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0437\u043D\u0430\u0447\u0435\u043D\u0438\u0435 \u0438\u0437 \u0441\u043F\u0438\u0441\u043A\u0430', children: children }));
    }
    return (_jsx(components.Menu, Object.assign({}, props, { children: children })));
};
const TimeSelect = (props) => {
    const { label, required, controlId, name, iconType, validationSchema, shouldValidate, className, placeholder, disabled, options, } = props;
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const { control, getValues } = useFormContext();
    const error = (shouldValidate && validationSchema) ? validateField(name, getValues(), validationSchema) : null;
    const handleOpen = useCallback(() => {
        setMenuIsOpen(true);
    }, []);
    const handleClose = useCallback(() => {
        setMenuIsOpen(false);
    }, []);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const customStyles = useMemo(() => ({
        control: (base) => (Object.assign(Object.assign({}, base), { background: disabled ? '#E2E2E2' : '#F2F2F2', borderColor: 'transparent', marginLeft: '-8px', height: '45px', boxShadow: null, '&:hover': {
            // Overwrittes the different states of border
            // borderColor: state.isFocused ? "red" : "blue"
            }, span: {
                borderColor: '#F2F2F2 !important',
            } })),
        placeholder: (base) => (Object.assign(Object.assign({}, base), { color: error ? '#EB5757' : '#333333' })),
        menuPortal: provided => (Object.assign(Object.assign({}, provided), { zIndex: 1001 })),
    }), [disabled, error]);
    const inputGroupComponent = (field) => {
        return (_jsx(InputGroup, { children: _jsx(Select, Object.assign({}, field, { value: field.value ? options.find((v) => v.value === field.value) : null, onChange: (e) => {
                    field.onChange(e.value);
                }, options: options, 
                // eslint-disable-next-line react/jsx-props-no-multi-spaces
                menuPortalTarget: document.body, menuPosition: 'fixed', menuPlacement: 'auto', isSearchable: false, menuShouldScrollIntoView: false, isDisabled: disabled, className: `simple-edit ${error ? 'simple-edit-error' : ''} ${disabled ? 'simple-edit-disabled' : ''} `, styles: customStyles, placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : label, menuIsOpen: isMobile ? menuIsOpen : undefined, onMenuOpen: isMobile ? handleOpen : undefined, components: {
                    Menu: (menuListProps) => (_jsx(MobileMenu, Object.assign({ isLoading: false, placement: 'bottom', minMenuHeight: 0, maxMenuHeight: 0, menuPlacement: 'bottom', menuPosition: 'fixed', menuShouldScrollIntoView: false }, menuListProps, { isMobile: isMobile, label: placeholder !== null && placeholder !== void 0 ? placeholder : label, handleClose: handleClose }))),
                    MenuList: (menuListProps) => (_jsx(CustomMenuList, Object.assign({}, menuListProps, { handleSelect: value => {
                            if (isMobile)
                                handleClose();
                            field.onChange(value);
                        } }))),
                } })) }));
    };
    return (_jsxs(Form.Group, { controlId: controlId, className: `component-row ${className}`, children: [label && (_jsx(Form.Label, { className: `label mb-0${required ? ' required' : ''}${iconType ? ' margin-left-label' : ''}${error ? ' error-color-font' : ''}`, children: label })), _jsx(Controller, { name: name, control: control, render: ({ field }) => {
                    if (iconType) {
                        return (_jsxs("div", { className: 'inputDesign', children: [_jsx("div", { className: 'icon', children: iconSelector(iconType) }), inputGroupComponent(field)] }));
                    }
                    return inputGroupComponent(field);
                } }), _jsx("div", { className: 'error-container', children: _jsx("div", { children: error !== null && error !== void 0 ? error : ' ' }) })] }));
};
export default inject('referenceStore')(observer(TimeSelect));
