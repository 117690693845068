// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  height: 100vh;
  width: 100vw;
}

:root {
  --primary-color: #8774e1;
  --text-primary-color: #8774e1;
  --bg-color: rgb(33, 33, 33);
  --secondary-bg-color: #2b2d31;
  --text-secondary-color: #d9d9d9;
  --hint-color: rgba(115, 115, 115, 0.8);
  --map-no-data-color: hsla(0, 0%, 60.8%, 0.49019607843137253);
  --map-success-color: rgba(130, 217, 141, 1);
  --map-warn-color-1: #fff113;
  --map-warn-color-2: #ff9200;
  --map-danger-color: #f44336;
  --space-sm: 8px;
  --space-md: 16px;
  --space-lg: 32px;
  --font-size-sm: 12px;
  --font-size-md: 22px;
  --font-size-lg: 32px;
  --border-radius-sm: 8px;
  --border-radius-md: 10px;
  --border-radius-lg: 20px;
}

.hint {
  width: 12em;
  min-height: 6em;
  padding: var(--space-md);
  border-radius: var(--border-radius-md);
  background-color: var(--bg-color);
  border: 1px var(--hint-color) solid;
  display: flex;
  flex-direction: column;
  transform: translateY(-100%);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.hint-header {
  color: var(--primary-color);
  font-size: var(--font-size-lg);
  margin-bottom: var(--space-sm);
  font-weight: bold;
}

.hint-hint {
  color: var(--hint-color);
  font-size: var(--font-size-sm);
  margin-bottom: var(--space-sm);
}

.hint-desc {
  font-size: var(--font-size-md);
  color: var(--text-secondary-color);
}

.map-custom-button {
  color: var(--primary-color);
  text-align: center;
  padding: 5px;
  font-size: 24px;
  font-weight: bold;
  z-index: 10;
}

.map-info {
  border-radius: var(--border-radius-lg);
  background-color: var(--bg-color);
  width: 8em;
  height: 8em;
  z-index: 20;
  padding: 12px;
}

.map-info-header {
  color: var(--primary-color);
  font-size: var(--font-size-md);
  font-weight: bold;
}

.map-info-text {
  color: var(--hint-color);
  font-size: var(--font-size-sm);
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/modules/map/styles.css"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,YAAA;AAAJ;;AAGE;EACE,wBAAA;EACA,6BAAA;EACA,2BAAA;EACA,6BAAA;EACA,+BAAA;EACA,sCAAA;EAEA,4DAAA;EACA,2CAAA;EACA,2BAAA;EACA,2BAAA;EACA,2BAAA;EAEA,eAAA;EACA,gBAAA;EACA,gBAAA;EAEA,oBAAA;EACA,oBAAA;EACA,oBAAA;EAEA,uBAAA;EACA,wBAAA;EACA,wBAAA;AAJJ;;AAOE;EACE,WAAA;EACA,eAAA;EACA,wBAAA;EACA,sCAAA;EACA,iCAAA;EACA,mCAAA;EACA,aAAA;EACA,sBAAA;EACA,4BAAA;EACA,wIAAA;AAJJ;;AAQE;EACE,2BAAA;EACA,8BAAA;EACA,8BAAA;EACA,iBAAA;AALJ;;AAQE;EACE,wBAAA;EACA,8BAAA;EACA,8BAAA;AALJ;;AAQE;EACE,8BAAA;EACA,kCAAA;AALJ;;AAQE;EACE,2BAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;AALJ;;AAQE;EACE,sCAAA;EACA,iCAAA;EACA,UAAA;EACA,WAAA;EACA,WAAA;EACA,aAAA;AALJ;;AAQE;EACE,2BAAA;EACA,8BAAA;EACA,iBAAA;AALJ;;AAQE;EACE,wBAAA;EACA,8BAAA;EACA,iBAAA;AALJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
