var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useCallback, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import { AsYouType } from 'libphonenumber-js';
import { useInterval, useMediaQuery } from '@react-hooks-library/core';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { toJS } from 'mobx';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
import { SmsInput } from '../core';
import { authActions } from './store/authStore';
import { schedulerActions } from './store/schedulerStore';
import { clientActions } from './store/clientStore';
const SmsPanelContent = (props) => {
    var _a;
    const { calculatePriceStore, schedulerStore } = props;
    const { watch, setValue } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const params = new URLSearchParams(window.location.search);
    const [countSeconds, setCountSeconds] = useState(60);
    const [confirmMessageError, setConfirmMessageError] = useState(null);
    const [token, setToken] = useState('');
    const [visible, setVisible] = useState(false);
    const hostname = ((_a = window.location.hostname) !== null && _a !== void 0 ? _a : '').trim();
    const repeatSms = useCallback(() => setCountSeconds((prev) => prev - 1), []);
    useInterval(repeatSms, 1000);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const { tireFittingPriceResponse, tireStoragePriceResponse, } = calculatePriceStore;
    const handleClose = () => {
        setValue('smsCode', null);
        stateAppActions.setAppStateOrder(4);
    };
    const handleСhangePhone = () => {
        setValue('smsCode', null);
        stateAppActions.setAppStateOrder(6);
    };
    const handleChallengeHidden = useCallback(() => setVisible(false), []);
    const sendSMS = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setValue('smsAlreadySend', true);
        setCountSeconds(60);
        setValue('smsAlreadySend', true);
        if ((!params.get('nosms')) && ((values === null || values === void 0 ? void 0 : values.captchaToken) || token)) {
            yield authActions.sendSms({ phoneNumber: values.phoneNumber, fcmToken: 'empty', captchaToken: (_a = values === null || values === void 0 ? void 0 : values.captchaToken) !== null && _a !== void 0 ? _a : token });
            setValue('captchaToken', null);
            setToken(null);
            setVisible(false);
        }
    });
    const putAppointment = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        let appointmentResponse = null;
        clientActions.updateClient({ firstName: values === null || values === void 0 ? void 0 : values.firstName, lastName: values === null || values === void 0 ? void 0 : values.lastName }, values === null || values === void 0 ? void 0 : values.token);
        if (values === null || values === void 0 ? void 0 : values.appointmentJournalId) {
            if ((values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) && (!(values === null || values === void 0 ? void 0 : values.hasTireStorage))) {
                appointmentResponse = yield schedulerActions.putAppointment({
                    vehicleTypeRefValueId: values === null || values === void 0 ? void 0 : values.vehicleTypeRefValueId,
                    diameterWheelRefValueId: values === null || values === void 0 ? void 0 : values.diameterWheelRefValueId,
                    tireOperationRefValueId: values === null || values === void 0 ? void 0 : values.tireOperationRefValueId,
                    tireRunflat: (values === null || values === void 0 ? void 0 : values.tireRunflatOptionValue) === 'yes',
                    isStorageRequired: false,
                    // returningWheelsKitOrderId?: string,
                    wheelBalancing: false,
                    vehicleNumber: values === null || values === void 0 ? void 0 : values.vehicleNumber,
                    isForeign: values === null || values === void 0 ? void 0 : values.isForeign,
                    service68: {
                        cost: (values === null || values === void 0 ? void 0 : values.isExactPrice) ? ((_a = tireFittingPriceResponse === null || tireFittingPriceResponse === void 0 ? void 0 : tireFittingPriceResponse.price) !== null && _a !== void 0 ? _a : 0) : 0,
                        tireCount: values === null || values === void 0 ? void 0 : values.tireCount,
                    },
                    // storageActionRefValueId?: 445,
                    // storageDurationInMonths?: values?.storageDurationInMonths,
                    // storedObjectRefValueId?:  values?.storedObjectRefValueId,
                    utmCampaign: (_b = values === null || values === void 0 ? void 0 : values.utmCampaign) !== null && _b !== void 0 ? _b : Cookies.get('avtoapp_utm_term'),
                    utmContent: (_c = values === null || values === void 0 ? void 0 : values.utmContent) !== null && _c !== void 0 ? _c : Cookies.get('avtoapp_utm_content'),
                    utmMedium: (_d = values === null || values === void 0 ? void 0 : values.utmMedium) !== null && _d !== void 0 ? _d : Cookies.get('avtoapp_utm_medium'),
                    utmSource: (_e = values === null || values === void 0 ? void 0 : values.utmSource) !== null && _e !== void 0 ? _e : Cookies.get('avtoapp_utm_source'),
                    utmTerm: (_f = values === null || values === void 0 ? void 0 : values.utmTerm) !== null && _f !== void 0 ? _f : Cookies.get('avtoapp_utm_term'),
                    sourceRefValueId: 470, // сайт
                    sourceDomain: hostname,
                }, values === null || values === void 0 ? void 0 : values.appointmentJournalId, values === null || values === void 0 ? void 0 : values.token);
            }
            if ((values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) && (values === null || values === void 0 ? void 0 : values.hasTireStorage)) {
                appointmentResponse = yield schedulerActions.putAppointment({
                    vehicleTypeRefValueId: values === null || values === void 0 ? void 0 : values.vehicleTypeRefValueId,
                    diameterWheelRefValueId: values === null || values === void 0 ? void 0 : values.diameterWheelRefValueId,
                    tireOperationRefValueId: values === null || values === void 0 ? void 0 : values.tireOperationRefValueId,
                    tireRunflat: (values === null || values === void 0 ? void 0 : values.tireRunflatOptionValue) === 'yes',
                    isStorageRequired: true,
                    // returningWheelsKitOrderId?: string,
                    wheelBalancing: false,
                    vehicleNumber: values === null || values === void 0 ? void 0 : values.vehicleNumber,
                    isForeign: values === null || values === void 0 ? void 0 : values.isForeign,
                    service68: {
                        cost: (values === null || values === void 0 ? void 0 : values.isExactPrice) ? ((_g = tireFittingPriceResponse === null || tireFittingPriceResponse === void 0 ? void 0 : tireFittingPriceResponse.price) !== null && _g !== void 0 ? _g : 0) : 0,
                        tireCount: values === null || values === void 0 ? void 0 : values.tireCount,
                    },
                    service69: {
                        cost: (_h = tireStoragePriceResponse === null || tireStoragePriceResponse === void 0 ? void 0 : tireStoragePriceResponse.price) !== null && _h !== void 0 ? _h : 0,
                        tireCount: values === null || values === void 0 ? void 0 : values.tireCountStorage,
                    },
                    storageActionRefValueId: 445,
                    storageDurationInMonths: values === null || values === void 0 ? void 0 : values.storageDurationInMonths,
                    storedObjectRefValueId: values === null || values === void 0 ? void 0 : values.storedObjectRefValueId,
                    utmCampaign: (_j = values === null || values === void 0 ? void 0 : values.utmCampaign) !== null && _j !== void 0 ? _j : Cookies.get('avtoapp_utm_term'),
                    utmContent: (_k = values === null || values === void 0 ? void 0 : values.utmContent) !== null && _k !== void 0 ? _k : Cookies.get('avtoapp_utm_content'),
                    utmMedium: (_l = values === null || values === void 0 ? void 0 : values.utmMedium) !== null && _l !== void 0 ? _l : Cookies.get('avtoapp_utm_medium'),
                    utmSource: (_m = values === null || values === void 0 ? void 0 : values.utmSource) !== null && _m !== void 0 ? _m : Cookies.get('avtoapp_utm_source'),
                    utmTerm: (_o = values === null || values === void 0 ? void 0 : values.utmTerm) !== null && _o !== void 0 ? _o : Cookies.get('avtoapp_utm_term'),
                }, values === null || values === void 0 ? void 0 : values.appointmentJournalId, values === null || values === void 0 ? void 0 : values.token);
            }
            if ((!(values === null || values === void 0 ? void 0 : values.tireOperationRefValueId)) && (values === null || values === void 0 ? void 0 : values.hasTireStorage)) {
                appointmentResponse = yield schedulerActions.putAppointment({
                    vehicleTypeRefValueId: values === null || values === void 0 ? void 0 : values.vehicleTypeRefValueId,
                    diameterWheelRefValueId: values === null || values === void 0 ? void 0 : values.diameterWheelRefValueId,
                    // tireOperationRefValueId: values?.tireOperationRefValueId,
                    // tireRunflat: false,
                    // isStorageRequired: false,
                    // returningWheelsKitOrderId?: string,
                    // wheelBalancing: false,
                    /* service68: {
                      cost: tireFittingPriceResponse?.price ?? 0,
                      tireCount: values?.tireCount,
                    }, */
                    service69: {
                        cost: (_p = tireStoragePriceResponse === null || tireStoragePriceResponse === void 0 ? void 0 : tireStoragePriceResponse.price) !== null && _p !== void 0 ? _p : 0,
                        tireCount: values === null || values === void 0 ? void 0 : values.tireCountStorage,
                    },
                    storageActionRefValueId: 445,
                    storageDurationInMonths: values === null || values === void 0 ? void 0 : values.storageDurationInMonths,
                    storedObjectRefValueId: values === null || values === void 0 ? void 0 : values.storedObjectRefValueId,
                    utmCampaign: (_q = values === null || values === void 0 ? void 0 : values.utmCampaign) !== null && _q !== void 0 ? _q : Cookies.get('avtoapp_utm_term'),
                    utmContent: (_r = values === null || values === void 0 ? void 0 : values.utmContent) !== null && _r !== void 0 ? _r : Cookies.get('avtoapp_utm_content'),
                    utmMedium: (_s = values === null || values === void 0 ? void 0 : values.utmMedium) !== null && _s !== void 0 ? _s : Cookies.get('avtoapp_utm_medium'),
                    utmSource: (_t = values === null || values === void 0 ? void 0 : values.utmSource) !== null && _t !== void 0 ? _t : Cookies.get('avtoapp_utm_source'),
                    utmTerm: (_u = values === null || values === void 0 ? void 0 : values.utmTerm) !== null && _u !== void 0 ? _u : Cookies.get('avtoapp_utm_term'),
                }, values === null || values === void 0 ? void 0 : values.appointmentJournalId, values === null || values === void 0 ? void 0 : values.token);
            }
            // Если в ответе есть appointmentResponse.id, то это успешный успех (но это не точно)
            if (appointmentResponse.id) {
                stateAppActions.setAppStateOrder(7);
            }
        }
    });
    const errorMessage = toJS(schedulerStore.error);
    const formatPhone = () => {
        var _a;
        const asYouType = new AsYouType('RU');
        asYouType.input((_a = values === null || values === void 0 ? void 0 : values.phoneNumber) !== null && _a !== void 0 ? _a : '');
        return asYouType.getNumber().formatInternational();
    };
    const translateError = (s) => {
        if (s === 'Wrong login or password') {
            return 'Неправильный код';
        }
        if (s === 'Password expired') {
            return 'Неправильный код';
        }
        return s;
    };
    const confirmSMS = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const confirmSms = yield authActions.confirmSms({
            phoneNumber: values.phoneNumber,
            password: values === null || values === void 0 ? void 0 : values.smsCode,
        });
        setValue('token', (_a = confirmSms.token) !== null && _a !== void 0 ? _a : null);
        setConfirmMessageError((_b = confirmSms.message) !== null && _b !== void 0 ? _b : null);
        // setCountSecondsError(7);
    });
    useEffect(() => {
        if ((values === null || values === void 0 ? void 0 : values.appointmentJournalId) && (values === null || values === void 0 ? void 0 : values.token)) {
            putAppointment();
        }
    }, [values === null || values === void 0 ? void 0 : values.token]);
    useEffect(() => {
        if ((values === null || values === void 0 ? void 0 : values.smsCode) && ((values === null || values === void 0 ? void 0 : values.smsCode.length) === 4)) {
            confirmSMS();
        }
    }, [values === null || values === void 0 ? void 0 : values.smsCode]);
    useEffect(() => {
        // TODO -  if (!values?.token) { - для отладки добавил, вырезать
        // данная проверка позволяет оформить повторный заказ без ввода СМС. Верно или нет ? Пока не понятно.
        if (!(values === null || values === void 0 ? void 0 : values.token) && !(values === null || values === void 0 ? void 0 : values.smsAlreadySend)) {
            setVisible(true);
        }
    }, []);
    /* useEffect(() => {
      if (countSecondsError === 0) {
        setConfirmMessageError(null);
      }
    }, [countSecondsError]); */
    useEffect(() => {
        if (token) {
            setValue('captchaToken', token);
            sendSMS();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);
    return (_jsxs("div", { className: 'modal-auth-form', children: [!isMobile && (_jsx(Row, { children: _jsxs(Col, { className: 'avtoapp_color_font_20', children: ["\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u0435 \u0437\u0430\u043F\u0438\u0441\u0438", _jsx(FontAwesomeIcon, { icon: faXmark, className: 'pull-right font-20-500-middle-gray-dark header-button', onClick: handleClose })] }) })), _jsx(Row, { className: 'text1', children: _jsx(Col, { className: 'font-14-400-black-noSpacing', children: "\u041C\u044B \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043B\u0438 \u043A\u043E\u0434 \u043D\u0430 \u0432\u0430\u0448 \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430:" }) }), _jsx(Row, { className: 'phone-line', children: _jsxs(Col, { className: 'phone-direction', onClick: handleСhangePhone, children: [_jsx("div", { className: 'font-14-400', children: formatPhone() }), _jsx("div", { className: 'edit-phone', children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C" })] }) }), (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.errorCode) === 'IMPOSSIBLE_ACTION'
                && (_jsxs(Row, { className: 'pt-4', children: [_jsx(Col, { md: 7, className: 'error-container pt-2', children: _jsx("div", { children: "\u0418\u0437\u0432\u0438\u043D\u0438\u0442\u0435, \u0432\u044B \u043D\u0435 \u043C\u043E\u0436\u0435\u0442\u0435 \u0437\u0430\u043F\u0438\u0441\u0430\u0442\u044C\u0441\u044F \u043D\u0430 \u0443\u043A\u0430\u0437\u0430\u043D\u043D\u0443\u044E \u0434\u0430\u0442\u0443. \u0412\u044B\u0431\u0435\u0440\u0435\u0442\u0435 \u0434\u0440\u0443\u0433\u0443\u044E \u0434\u0430\u0442\u0443." }) }), _jsx(Col, { md: 5, children: _jsx(Button, { className: 'simple-button', variant: 'primary', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    schedulerActions.clearError();
                                    stateAppActions.setAppStateOrder(3);
                                    setValue('date', null);
                                    setValue('time', null);
                                }), children: "\u0412\u044B\u0431\u043E\u0440 \u0434\u0430\u0442\u044B" }) })] })), (errorMessage === null || errorMessage === void 0 ? void 0 : errorMessage.errorCode) !== 'IMPOSSIBLE_ACTION' && (_jsx(Row, { children: _jsxs("div", { className: (countSeconds <= 0) ? 'sms-line sms-line-reverse' : 'sms-line', children: [_jsx("div", { children: _jsx(SmsInput, { label: '\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043A\u043E\u0434', name: 'smsCode', error: translateError(confirmMessageError) }) }), _jsxs("div", { className: 'repeat', children: [(countSeconds > 0)
                                    && (_jsxs(_Fragment, { children: [_jsx("div", { className: 'label', children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E" }), _jsxs("div", { className: 'seconds', children: [countSeconds, " \u0441\u0435\u043A"] })] })), (countSeconds <= 0)
                                    && (_jsx(Button, { className: 'simple-button', variant: 'primary', onClick: () => {
                                            if (token) {
                                                sendSMS();
                                            }
                                            else {
                                                setVisible(true);
                                            }
                                        }, children: "\u041E\u0422\u041F\u0420\u0410\u0412\u0418\u0422\u042C \u0421\u041C\u0421" }))] })] }) })), visible
                && (_jsx(Row, { children: _jsx(InvisibleSmartCaptcha, { sitekey: process.env.YANDEX_CAPTCHA_KEY, onSuccess: setToken, onChallengeHidden: handleChallengeHidden, visible: visible }) }))] }));
};
export default inject('calculatePriceStore', 'schedulerStore')(observer(SmsPanelContent));
