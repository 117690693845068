import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import { inject, observer } from 'mobx-react';
import { useMediaQuery, useScroll } from '@react-hooks-library/core';
import { useFormContext } from 'react-hook-form';
import TireOperationSelectPanel from '../order/TireOperationSelectPanel';
import ParametersPanel from '../order/ParametersPanel';
import FillingClientDataPanel from '../order/FillingClientDataPanel';
import OfficeSelectorPanel from '../order/OfficeSelectorPanel';
import ConfirmationPanel from '../order/ConfirmationPanel';
import SmsModalPanel from '../order/SmsModalPanel';
import SuccessfulSuccessModalPanel from '../order/SuccessfulSuccessModalPanel';
import PhoneModalPanel from '../order/PhoneModalPanel';
import { exactPrice } from '../order/consts';
const LeftSidePanel = (props) => {
    var _a;
    const { stateAppStore } = props;
    const stateOrder = (_a = stateAppStore === null || stateAppStore === void 0 ? void 0 : stateAppStore.stateOrder) !== null && _a !== void 0 ? _a : 0;
    const { setValue, getValues } = useFormContext(); // retrieve all hook methods
    const box = useRef(null);
    useScroll(box, ({ scrollY }) => { setValue('scrollY', scrollY); });
    const values = getValues();
    const isMobile = useMediaQuery('(max-width: 768px)');
    useEffect(() => {
        var _a, _b;
        if ((_a = box === null || box === void 0 ? void 0 : box.current) === null || _a === void 0 ? void 0 : _a.scrollTo) {
            (_b = box === null || box === void 0 ? void 0 : box.current) === null || _b === void 0 ? void 0 : _b.scrollTo(0, 0);
        }
        setValue('scrollY', 0);
    }, [stateOrder]);
    const getTireOperation = (tireOperation) => {
        if (tireOperation) {
            switch (tireOperation) {
                case 1:
                    return 361;
                case 2:
                    return 244;
                case 3:
                    return 487;
                default:
                    return null;
            }
        }
        ;
        return null;
    };
    globalThis.setTireOperation = (tireOperation) => {
        if (tireOperation) {
            setValue('tireOperationRefValueId', getTireOperation(tireOperation));
            setValue('isExactPrice', exactPrice.includes(getTireOperation(tireOperation)));
        }
        ;
    };
    return (_jsx("div", { className: `left-side-box${(values === null || values === void 0 ? void 0 : values.minimized) ? ' minimized' : ''}`, ref: box, children: _jsxs(Container, { fluid: true, className: 'drop-padding-and-margin', children: [stateOrder === 0
                    && _jsx(TireOperationSelectPanel, {}), stateOrder === 1
                    && _jsx(ParametersPanel, {}), stateOrder === 2
                    && _jsx(OfficeSelectorPanel, {}), stateOrder === 3
                    && _jsx(FillingClientDataPanel, {}), ((stateOrder === 4)
                    || (stateOrder === 5 && !isMobile)
                    || (stateOrder === 6 && !isMobile)
                    || (stateOrder === 7 && !isMobile))
                    && _jsx(ConfirmationPanel, {}), stateOrder === 5
                    && _jsx(SmsModalPanel, {}), stateOrder === 6
                    && _jsx(PhoneModalPanel, {}), stateOrder === 7
                    && _jsx(SuccessfulSuccessModalPanel, {})] }) }));
};
export default inject('stateAppStore')(observer(LeftSidePanel));
