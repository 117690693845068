import styled from 'styled-components';
import { Card, Row } from 'react-bootstrap';
export const CustomCard = styled(Card) `
  @media (min-width: 769px) {
    width: 336px;
  }
  background-color: white;
  border-radius: 14px;
  border: 0;
  font-size: 14px;
  
  .card-img {
    width: 100%;
    height: 150px;
    border-radius: 14px 14px 0 0;
    object-fit: cover;
  }
  
  .card-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: #382A86;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-subtitle {
    margin-top: 4px;
    min-height: 16px;
    height: 16px;
  }
`;
export const Address = styled.div `
  width: 100%;
  display: flex;
  margin-top: 8px;

  svg {
    margin-right: 6px;
  }

  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: black;
  
  span {
    width: calc(100% - 36px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const Schedule = styled(Address) `
  svg:last-child {
    margin-top: 3px;
    margin-left: 6px;
    margin-right: 0;
  }

  span {
    width: calc(100% - 50px);
  }
`;
export const Actions = styled(Row) `
  width: calc(100% - 12px);
  margin: 4px 6px 14px 6px;
  --bs-gutter-x: 12px;

  div {
    button {
      height: 50px;
    }
  }
`;
