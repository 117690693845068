import styled from 'styled-components';
import OfficeCard from '../../core/visualComponents/OfficeCard';
export const CustomOfficeCard = styled(OfficeCard) `
  position: relative;
  margin-top: -${({ stateOrder, hasPrice }) => {
    let bottom = 362;
    if (stateOrder === 2) {
        bottom += 66;
    }
    if (hasPrice) {
        bottom += 70;
    }
    return `${bottom}px`;
}};
  margin-left: -163px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.22);
  z-index: 100;
  cursor: default;

  // TODO: Тут адаптив подробностей об офисе
  //@media (max-width: 767px) {
  //  position: fixed;
  //  bottom: 0;
  //  left: 0;
  //}
`;
