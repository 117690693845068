import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { Row, Col } from 'react-bootstrap';
import PriceComponent from './PriceComponent';
import { tireFittingRename } from './PriceFunc';
const PriceFrom = (props) => {
    var _a, _b;
    const { office } = props;
    const { watch } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const params = new URLSearchParams(window.location.search);
    // согласно требованиям бизнеса задаём параметры:
    // https://avtoapp.evateam.ru/project/Document/DOC-000545#raschet-czeny-ot
    /* const tireFittings = [
      { tireOperationRefValueId: 244, tireCount: 4, label: 'Шиномонтаж' },
      { tireOperationRefValueId: 361, tireCount: 4, label: 'Шиномонтаж' },
      { tireOperationRefValueId: 245, tireCount: 4, label: 'Балансировка' },
      { tireOperationRefValueId: 243, tireCount: 1, label: 'Ремонт шин' },
      { tireOperationRefValueId: 360, tireCount: 1, label: 'Ремонт шин' },
      { tireOperationRefValueId: 368, tireCount: 1, label: 'Ремонт шин' },
      { tireOperationRefValueId: 362, tireCount: 1, label: 'Ниппель' },
      { tireOperationRefValueId: 363, tireCount: 1, label: 'Дошиповка' },
    ];
  
    const tireFitting = tireFittings
      // eslint-disable-next-line eqeqeq
      .find(v => v.tireOperationRefValueId == values?.tireOperationRefValueId); */
    const tireFitting = tireFittingRename(values === null || values === void 0 ? void 0 : values.tireOperationRefValueId);
    return (_jsxs(_Fragment, { children: [(!!params.get('debug'))
                && (_jsx(Row, { className: 'pt-2', children: _jsxs(Col, { className: 'label', children: ["\u041F\u043E \u043E\u0444\u0438\u0441\u0443 \"", office.name, "\" \u0440\u0430\u0441\u0447\u0451\u0442:"] }) })), _jsxs("div", { className: 'price-components-row', children: [!!(values === null || values === void 0 ? void 0 : values.tireOperationRefValueId)
                        && (_jsx("div", { children: _jsx(PriceComponent, { typePrice: 0, office: office, priceFrom: true, priceFromTireFittingPriceRequestInterface: {
                                    diameterWheelRefValueId: 30,
                                    partnerOfficeId: office.id,
                                    tireCount: (_a = tireFitting === null || tireFitting === void 0 ? void 0 : tireFitting.tireCount) !== null && _a !== void 0 ? _a : 1,
                                    tireOperationRefValueId: (_b = values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) !== null && _b !== void 0 ? _b : 244,
                                    tireRunflat: false,
                                    vehicleTypeRefValueId: 43,
                                    wheelBalancing: false,
                                }, label: tireFitting === null || tireFitting === void 0 ? void 0 : tireFitting.label }) })), !!(values === null || values === void 0 ? void 0 : values.hasTireStorage)
                        && (_jsx("div", { children: _jsx(PriceComponent, { typePrice: 1, office: office, priceFrom: true, priceFromTireStoragePriceRequestInterface: {
                                    diameterWheelRefValueId: 30,
                                    tireCount: 4,
                                    storageDurationInMonths: 6,
                                    storedObjectRefValueId: 379,
                                    partnerOfficeId: office.id,
                                } }) }))] })] }));
};
export default inject('calculatePriceStore')(observer(PriceFrom));
