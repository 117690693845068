import styled from 'styled-components';
export const PinWrapper = styled.div `
  position: absolute;
  top: 0px;
  left: 0px;
  width: 43px;
  height: 50px;
  margin-top: -50px;
  margin-left: -21.5px;
  font-size: 32px;

  svg {
    fill: ${({ selected }) => (selected ? '#EB5757' : '#6346A4')};
    
    .colorful {
      fill: ${({ selected }) => (selected ? '#EB5757' : '#6346A4')};
    }
  }

  &:hover {
    z-index: 10;
    cursor: pointer;

    svg {
      fill: #EB5757;

      .colorful {
        fill: #EB5757;
      }
    }
  }
`;
