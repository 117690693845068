// import { CoordinatesType } from './types';
export const DELTAS_FOR_ZOOM = {
    1: 170,
    2: 70,
    3: 32,
    4: 11,
    5: 7,
    6: 3,
    7: 1.6,
    8: 0.6,
    9: 0.37,
    10: 0.2,
    11: 0.11,
    12: 0.03,
    13: 0.013,
    14: 0.008,
    15: 0.0035,
    16: 0.002,
    17: 0.0013,
    18: 0.0008,
    19: 0.0004,
    20: 0.0002,
    21: 0.0001,
    22: 0.0001,
    23: 0.0001,
};
export const MOBILE_DELTAS_FOR_ZOOM = {
    1: -170,
    2: -70,
    3: -32,
    4: -15,
    5: -7,
    6: -3,
    7: -1.3,
    8: -0.6,
    9: -0.28,
    10: -0.15,
    11: -0.07,
    12: -0.03,
    13: -0.013,
    14: -0.008,
    15: -0.0035,
    16: -0.002,
    17: -0.0013,
    18: -0.0008,
    19: -0.0004,
    20: -0.0002,
    21: -0.0001,
    22: -0.0001,
    23: -0.0001,
};
export const DEFAULT_ZOOM = 11;
export const MAX_ZOOM = 16;
export const MIN_ZOOM = 4;
export const MAP_OPTIONS_STYLES = [
    /* {
      featureType: 'administrative',
      stylers: [
        { visibility: 'off' },
      ],
    }, */
    {
        featureType: 'poi',
        stylers: [
            { visibility: 'off' },
        ],
    },
];
/* export const MAP_OPTIONS = {
  minZoom: MIN_ZOOM,
  maxZoom: MAX_ZOOM,
  disableDefaultUI: true,
  clickableIcons: false,
  // Странная штука
  // По АПИ это должно работать и скрывать с карты именно те элементы которые нам нужны, но почему-то не работает
  // https://github.com/google-map-react/google-map-react/blob/master/API.md#options-funcobject
  // https://developers.google.com/maps/documentation/javascript/style-reference?hl=ru
  styles: MAP_OPTIONS_STYLES,
}; */
