import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
// import "@fontsource/roboto"
import { Provider } from 'mobx-react';
import { isAndroid } from 'react-device-detect';
import { YMInitializer } from 'react-yandex-metrika';
import * as stores from './lib/mobx/stores';
import PrimaryPanel from './modules/layers/PrimaryPanel';
import './scss/main.scss';
const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height-mobile', `${window.innerHeight}px`);
};
const App = () => {
    // Рассчет высоты всего приложения на мобилках, только для android (для iOS стилями обошлось)
    useEffect(() => {
        if (isAndroid) {
            appHeight();
        }
    });
    return (_jsx(Provider, Object.assign({}, stores, { children: _jsxs("div", { className: 'main-container', style: { maxHeight: isAndroid ? `${window.innerHeight}px` : '' }, children: [_jsx(YMInitializer, { accounts: [92740776] }), _jsx(PrimaryPanel, {})] }) })));
};
export { App };
