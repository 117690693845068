import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { inject, observer } from 'mobx-react';
import Container from 'react-bootstrap/Container';
import { Logo, Avtoapp } from '../core';
import { officeActions } from '../map/store/officeStore';
import { Col, Row } from 'react-bootstrap';
const MenuPanel = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { officeStore } = props;
    const params = new URLSearchParams(window.location.search);
    return (_jsx("div", { className: 'menu-box', children: _jsx(Container, { fluid: true, children: _jsxs("div", { className: 'menu-layer', children: [_jsx("div", { className: 'd-flex align-items-center mobile-hidden', children: _jsx("div", { className: 'avtoapp', children: _jsx(Avtoapp, {}) }) }), _jsx("div", { className: 'd-flex align-items-center', children: _jsx("div", { className: 'logo', children: _jsx(Logo, {}) }) }), _jsxs("div", { className: 'd-flex align-items-center header-info', children: ["\u0428\u0438\u043D\u043E\u043C\u043E\u043D\u0442\u0430\u0436 ", _jsx("br", {}), " \u0438 \u0425\u0440\u0430\u043D\u0435\u043D\u0438\u0435 \u0448\u0438\u043D"] }), params.get('debug-zoom') &&
                        _jsx(Row, { children: _jsxs(Col, { children: [_jsxs("div", { children: [" zoom: ", (_a = officeStore === null || officeStore === void 0 ? void 0 : officeStore.location) === null || _a === void 0 ? void 0 : _a.zoom, "   \u041E\u041A\u0420(zoom): ", Math.round((_b = officeStore === null || officeStore === void 0 ? void 0 : officeStore.location) === null || _b === void 0 ? void 0 : _b.zoom), " _\u041A\u043E\u044D\u0444.:  ", _jsx("input", { name: 'myInput', value: officeStore.koef, onChange: (event) => {
                                                    var _a;
                                                    officeActions.updateKoef((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value);
                                                } }), " "] }), _jsxs("div", { children: [" lng ", (_c = officeStore === null || officeStore === void 0 ? void 0 : officeStore.location) === null || _c === void 0 ? void 0 : _c.center[0], " lat ", (_d = officeStore === null || officeStore === void 0 ? void 0 : officeStore.location) === null || _d === void 0 ? void 0 : _d.center[1], " "] }), _jsxs("div", { children: [" \u041E\u0444\u0438\u0441 lng ", (_e = officeStore === null || officeStore === void 0 ? void 0 : officeStore.selectedOffice) === null || _e === void 0 ? void 0 : _e.lng, " lat ", (_f = officeStore === null || officeStore === void 0 ? void 0 : officeStore.selectedOffice) === null || _f === void 0 ? void 0 : _f.lat, " "] })] }) }), _jsx("div", { className: 'd-flex align-items-center header-mobile-actions' })] }) }) }));
};
export default inject('officeStore')(observer(MenuPanel));
