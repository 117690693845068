var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { format } from 'date-fns';
import { Row, Col } from 'react-bootstrap';
import { useEffect, useMemo, useRef, useState, } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { toJS } from 'mobx';
import { useScrollWithShadow } from '../core/hooks/useScrollWithShadow';
import { SimpleInput, VehicleNumberInput, OfficeContextView, PriceComponentRow, PriceV2Component, Header, Footer, PhoneInput, } from '../core';
import DatePicker from '../core/components/DatePicker';
import TimeSelect from '../core/selectors/TimeSelect';
import { fillingClientData, fillingClientData5koleso } from './validation';
import { validateField } from '../core/components/validation';
import { schedulerActions, } from './store/schedulerStore';
const FillingClientDataPanel = (props) => {
    var _a, _b;
    const { schedulerStore } = props;
    const datesResponse = schedulerStore.datesResponse;
    const timesResponse = (_a = schedulerStore.timesResponse) !== null && _a !== void 0 ? _a : { items: [] };
    const { datesRequest } = schedulerStore;
    const { watch, setValue, getValues } = useFormContext(); // retrieve all hook methods
    const [shouldValidate, setShouldValidate] = useState(false);
    const [loading, setLoading] = useState(false);
    const values = getValues();
    const { partnerOfficeId } = values;
    const needVehicleNumber = (_b = values === null || values === void 0 ? void 0 : values.office) === null || _b === void 0 ? void 0 : _b.needVehicleNumber;
    const validationSchema = (needVehicleNumber) ? fillingClientData5koleso : fillingClientData;
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const vehicleNumberRef = useRef(null);
    const phoneRef = useRef(null);
    const { scroller, positions } = useScrollWithShadow();
    const clearReserve = (appointmentJournalId, clearTime) => __awaiter(void 0, void 0, void 0, function* () {
        if (values.appointmentJournalId || appointmentJournalId) {
            try {
                yield schedulerActions.deleteReserve(appointmentJournalId !== null && appointmentJournalId !== void 0 ? appointmentJournalId : values.appointmentJournalId);
                setValue('appointmentJournalId', null);
                if (clearTime) {
                    setValue('time', null, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                }
                setValue('localTime', null, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            }
            catch (error) {
                setValue('appointmentJournalId', null);
                if (clearTime) {
                    setValue('time', null, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                }
                setValue('localTime', null, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
            }
        }
    });
    const loadData = () => __awaiter(void 0, void 0, void 0, function* () {
        if ((datesRequest === null || datesRequest === void 0 ? void 0 : datesRequest.partnerOfficeId) !== partnerOfficeId) {
            yield schedulerActions.getDates({ partnerOfficeId, serviceId: values === null || values === void 0 ? void 0 : values.serviceId });
        }
    });
    const loadTimes = (val) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            yield clearReserve(null, true);
            if (partnerOfficeId && (val === null || val === void 0 ? void 0 : val.date)) {
                yield schedulerActions.getTimes({ partnerOfficeId, serviceId: val === null || val === void 0 ? void 0 : val.serviceId, date: format(val === null || val === void 0 ? void 0 : val.date, 'yyyy-MM-dd') });
            }
        }
        finally {
            setLoading(false);
        }
    });
    const getReserve = (val) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (val === null || val === void 0 ? void 0 : val.time) {
            const times = (_a = toJS(schedulerStore.timesResponse)) !== null && _a !== void 0 ? _a : { items: [] };
            // eslint-disable-next-line eqeqeq
            const x = times.items.find(v => { var _a; return v.unixTime == ((_a = val === null || val === void 0 ? void 0 : val.time) === null || _a === void 0 ? void 0 : _a.toString()); });
            if (partnerOfficeId) {
                if (val === null || val === void 0 ? void 0 : val.appointmentJournalId) {
                    yield clearReserve(val === null || val === void 0 ? void 0 : val.appointmentJournalId);
                }
                const reserve = yield schedulerActions.getReserve({
                    partnerOfficeId,
                    serviceId: values === null || values === void 0 ? void 0 : values.serviceId,
                    date: format(val === null || val === void 0 ? void 0 : val.date, 'yyyy-MM-dd'),
                    time: x === null || x === void 0 ? void 0 : x.localTime,
                });
                // setValue('time', val?.time);
                // if (values?.time !== val?.time) {
                // setValue('time', val?.time);
                // }
                setValue('localTime', (_b = x === null || x === void 0 ? void 0 : x.localTime) !== null && _b !== void 0 ? _b : '');
                setValue('appointmentJournalId', reserve.appointmentJournalId);
            }
        }
    });
    useEffect(() => {
        loadData();
    }, []);
    // Callback version of watch.  It's your responsibility to unsubscribe when done.
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'time') {
                if ((value === null || value === void 0 ? void 0 : value.time) && type === 'change') {
                    getReserve(value);
                }
            }
            if (name === 'date') {
                setValue('time', null, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                setValue('localTime', null, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
                loadTimes(value);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    const availableDates = useMemo(() => (datesResponse !== null && datesResponse !== void 0 ? datesResponse : { items: [] }).items.map((availableDate) => {
        return new Date(availableDate);
    }), [datesResponse]);
    /*   if (lastName.current !== null) {
      lastName.current.focus();
    } */
    const optionsTimes = useMemo(() => timesResponse.items.map((v) => {
        return {
            value: v.unixTime,
            label: v.localTime.split(':').slice(0, 2).join(':'),
        };
    }), [timesResponse]);
    const validation = () => {
        setShouldValidate(true);
        return !!validationSchema.isValidSync(values);
    };
    const handleFocus = () => {
        if ((firstNameRef.current !== null) && !!validateField('firstName', values, validationSchema)) {
            firstNameRef.current.focus();
            return;
        }
        if ((lastNameRef.current !== null) && !!validateField('lastName', values, validationSchema)) {
            lastNameRef.current.focus();
            return;
        }
        if ((vehicleNumberRef.current !== null) && !!validateField('vehicleNumber', values, validationSchema)) {
            vehicleNumberRef.current.focus();
            return;
        }
        if ((phoneRef.current !== null) && !!validateField('phoneNumber', values, validationSchema)) {
            phoneRef.current.focus();
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Header, { returnNumber: 2, header: '\u041E\u0444\u043E\u0440\u043C\u0438\u0442\u0435 \u0437\u0430\u044F\u0432\u043A\u0443', shadow: positions.top }), _jsxs("div", { className: 'padding-main-layer filling-client-data scroll-wrapper', ref: scroller, children: [(values === null || values === void 0 ? void 0 : values.office)
                        && (_jsx(Row, { children: _jsx(Col, { children: _jsx(OfficeContextView, { showBorder: true }) }) })), _jsx(PriceComponentRow, { office: values === null || values === void 0 ? void 0 : values.office }), _jsx(SimpleInput, { label: '\u0418\u043C\u044F', name: 'firstName', iconType: 'men', shouldValidate: shouldValidate, validationSchema: validationSchema, ref: firstNameRef, autoFocus: true }), !needVehicleNumber
                        && (_jsx(SimpleInput, { label: '\u0424\u0430\u043C\u0438\u043B\u0438\u044F', name: 'lastName', iconType: 'men', shouldValidate: shouldValidate, validationSchema: validationSchema, ref: lastNameRef })), needVehicleNumber
                        && (_jsx(VehicleNumberInput, { label: '\u0413\u043E\u0441.\u043D\u043E\u043C\u0435\u0440', name: 'vehicleNumber', iconType: 'number', shouldValidate: shouldValidate, validationSchema: validationSchema, ref: vehicleNumberRef })), _jsx(PhoneInput, { label: '\u0422\u0435\u043B\u0435\u0444\u043E\u043D', name: 'phoneNumber', iconType: 'phone', type: 'tel', shouldValidate: shouldValidate, validationSchema: validationSchema, ref: phoneRef }), _jsx(DatePicker, { includeDates: availableDates, label: '\u0414\u0430\u0442\u0430', name: 'date', iconType: 'calendar', shouldValidate: shouldValidate, validationSchema: validationSchema, error: ((values === null || values === void 0 ? void 0 : values.date) && optionsTimes.length === 0 && !loading) ? 'Нет свободного времени' : null }), _jsx(TimeSelect, { label: '\u0412\u0440\u0435\u043C\u044F', name: 'time', iconType: 'clock', options: optionsTimes, disabled: !(values === null || values === void 0 ? void 0 : values.date) || optionsTimes.length === 0, placeholder: '\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0432\u0440\u0435\u043C\u044F', shouldValidate: shouldValidate, validationSchema: validationSchema }), _jsx(PriceV2Component, {})] }), _jsx(Footer, { label: '\u0414\u0430\u043B\u0435\u0435', nextNumber: 4, validation: validation, onClick: handleFocus, shadow: positions.bottom })] }));
};
export default inject('referenceStore', 'officeStore', 'schedulerStore')(observer(FillingClientDataPanel));
