import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { format } from 'date-fns';
import { Row, Col } from 'react-bootstrap';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { Header, OfficeContextView, PriceV2Component, Footer, Subsection, } from '../core';
const ConfirmationPanel = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { referenceStore } = props;
    const { ref56 } = referenceStore;
    const { watch } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const { office } = values;
    const tireOperationRefValueId = (typeof (values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) === 'string')
        ? parseInt(values === null || values === void 0 ? void 0 : values.tireOperationRefValueId, 10)
        : values === null || values === void 0 ? void 0 : values.tireOperationRefValueId;
    return (_jsxs(_Fragment, { children: [_jsx(Header, { returnNumber: 3, header: '\u041F\u043E\u0434\u0442\u0432\u0435\u0440\u0434\u0438\u0442\u0435 \u0437\u0430\u043F\u0438\u0441\u044C' }), _jsxs("div", { className: 'padding-main-layer confirmation scroll-wrapper', children: [_jsx(Subsection, { label: '\u0412\u044B\u0431\u0440\u0430\u043D\u043D\u044B\u0435 \u0443\u0441\u043B\u0443\u0433\u0438', fontType: 20 }), (values === null || values === void 0 ? void 0 : values.hasTireStorage)
                        && (_jsx(Row, { className: 'text', children: _jsx(Col, { xs: 12, children: "\u0425\u0440\u0430\u043D\u0435\u043D\u0438\u0435 \u0448\u0438\u043D" }) })), (values === null || values === void 0 ? void 0 : values.tireOperationRefValueId)
                        && (_jsx(Row, { className: 'text', children: _jsx(Col, { xs: 12, children: (_b = (_a = ref56.find(v => v.id === tireOperationRefValueId)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '' }) })), _jsx(Row, { className: 'delimiter', children: _jsx("div", {}) }), office
                        && (_jsx(Row, { children: _jsx(Col, { children: _jsx(OfficeContextView, {}) }) })), _jsx(Row, { className: 'delimiter', children: _jsx("div", {}) }), _jsx(Subsection, { label: '\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u043A\u043B\u0438\u0435\u043D\u0442\u0435', fontType: 20 }), _jsx(Row, { className: 'text', children: _jsx(Col, { xs: 12, children: `${(_c = values === null || values === void 0 ? void 0 : values.lastName) !== null && _c !== void 0 ? _c : ''} ${(_d = values === null || values === void 0 ? void 0 : values.firstName) !== null && _d !== void 0 ? _d : ''}` }) }), _jsx(Row, { className: 'text', children: _jsx(Col, { xs: 12, children: values === null || values === void 0 ? void 0 : values.phoneNumber }) }), _jsx(Row, { className: 'delimiter', children: _jsx("div", {}) }), _jsx(Subsection, { label: '\u0418\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044F \u043E \u0432\u0438\u0437\u0438\u0442\u0435', fontType: 20 }), _jsx(Row, { className: 'text', children: _jsx(Col, { xs: 12, children: `${format(values === null || values === void 0 ? void 0 : values.date, 'dd.MM.yyyy')}  в ${(_f = (_e = values === null || values === void 0 ? void 0 : values.localTime) === null || _e === void 0 ? void 0 : _e.split(':').slice(0, 2).join(':')) !== null && _f !== void 0 ? _f : '--.--.--'}` }) }), _jsx(Row, { className: 'delimiter', children: _jsx("div", {}) }), _jsx(PriceV2Component, {})] }), _jsx(Footer, { label: '\u0414\u0430\u043B\u0435\u0435', nextNumber: 5 })] }));
};
export default inject('referenceStore')(observer(ConfirmationPanel));
