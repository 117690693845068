var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
// get our fontawesome imports
import { faRuble } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEqual from 'lodash/isEqual';
import { Bonus } from '..';
import { paramsTireStorageObj, paramsTireFittingObj, bonusTireFitting, bonusTireStorage, tireFittingRename, } from './PriceFunc';
import { calculatePriceActions, } from '../../order/store/calculatePriceStore';
const PriceV2Component = () => {
    var _a, _b, _c, _d, _e;
    const { watch } = useFormContext(); // retrieve all hook methods
    const values = watch();
    /*   const {
      tireFittingPriceResponse,
      tireStoragePriceResponse,
    } = calculatePriceStore; */
    const [tireFittingPriceResponse, setTireFittingPriceResponse] = useState(null);
    const [tireFittingPriceRequest, setTireFittingPriceRequest] = useState(null);
    const [tireStoragePriceResponse, setTireStoragePriceResponse] = useState(null);
    const [tireStoragePriceRequest, setTireStoragePriceRequest] = useState(null);
    const paramsTireFitting = paramsTireFittingObj(values);
    const paramsTireStorage = paramsTireStorageObj(values);
    const loadData = () => __awaiter(void 0, void 0, void 0, function* () {
        // if (!values?.partnerOfficeId && !priceFrom) return;
        const isNoEqualTireFittingRequest = !tireFittingPriceRequest || !isEqual(paramsTireFitting, tireFittingPriceRequest);
        if (isNoEqualTireFittingRequest && (values === null || values === void 0 ? void 0 : values.tireOperationRefValueId)) {
            /* calculatePriceActions.getTireFittingPrice(
              paramsTireFitting
            ); */
            calculatePriceActions.getTireFittingPrice(paramsTireFitting).then((res) => {
                setTireFittingPriceResponse(res);
                setTireFittingPriceRequest(paramsTireFitting);
            });
        }
        const isNoEqualTireStorageRequest = !tireStoragePriceRequest || !isEqual(paramsTireStorage, tireStoragePriceRequest);
        if (isNoEqualTireStorageRequest) {
            calculatePriceActions.getTireStoragePrice(paramsTireStorage).then((res) => {
                setTireStoragePriceResponse(res);
                setTireStoragePriceRequest(paramsTireStorage);
            });
        }
    });
    useEffect(() => {
        loadData();
    }, []);
    const storageKoef = (values === null || values === void 0 ? void 0 : values.hasTireStorage) ? 1 : 0;
    const tireOperationKoef = (values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) ? 1 : 0;
    const totalCost = ((_a = tireFittingPriceResponse === null || tireFittingPriceResponse === void 0 ? void 0 : tireFittingPriceResponse.price) !== null && _a !== void 0 ? _a : 0) * tireOperationKoef
        + ((_b = tireStoragePriceResponse === null || tireStoragePriceResponse === void 0 ? void 0 : tireStoragePriceResponse.price) !== null && _b !== void 0 ? _b : 0) * storageKoef;
    const totalBonus = ((_c = tireFittingPriceResponse === null || tireFittingPriceResponse === void 0 ? void 0 : tireFittingPriceResponse.price) !== null && _c !== void 0 ? _c : 0) * bonusTireFitting(values === null || values === void 0 ? void 0 : values.office) * tireOperationKoef
        + ((_d = tireStoragePriceResponse === null || tireStoragePriceResponse === void 0 ? void 0 : tireStoragePriceResponse.price) !== null && _d !== void 0 ? _d : 0) * bonusTireStorage(values === null || values === void 0 ? void 0 : values.office) * storageKoef;
    const tireFitting = tireFittingRename(values === null || values === void 0 ? void 0 : values.tireOperationRefValueId);
    return (_jsxs(_Fragment, { children: [_jsxs(Row, { className: 'price-v2-component', children: [(values === null || values === void 0 ? void 0 : values.isExactPrice)
                        && (_jsxs("div", { className: 'row-price', children: [_jsx("div", { className: 'avtoapp_color_font_16', children: " \u0421\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u0443\u0441\u043B\u0443\u0433" }), _jsxs("div", { className: 'avtoapp_color_font_22_700', children: [totalCost, _jsx(FontAwesomeIcon, { icon: faRuble, style: { height: '0.9rem', paddingLeft: '5px' } })] })] })), ((values === null || values === void 0 ? void 0 : values.tireOperationRefValueId) && (tireFittingPriceResponse === null || tireFittingPriceResponse === void 0 ? void 0 : tireFittingPriceResponse.price))
                        ? (_jsxs("div", { className: 'row-price font-16-400-gray-dark', children: [_jsxs("div", { children: [" ", (_e = tireFitting === null || tireFitting === void 0 ? void 0 : tireFitting.label) !== null && _e !== void 0 ? _e : 'Шиномонтаж', "  "] }), _jsxs("div", { children: [`${!(values === null || values === void 0 ? void 0 : values.isExactPrice) ? 'от ' : ''}${tireFittingPriceResponse.price}`, _jsx(FontAwesomeIcon, { icon: faRuble, style: { height: '0.9rem', paddingLeft: '5px' } })] })] })) : null, ((values === null || values === void 0 ? void 0 : values.hasTireStorage) && (tireStoragePriceResponse === null || tireStoragePriceResponse === void 0 ? void 0 : tireStoragePriceResponse.price))
                        ? (_jsxs("div", { className: 'row-price font-16-400-gray-dark', children: [_jsx("div", { children: " \u0425\u0440\u0430\u043D\u0435\u043D\u0438\u0435 \u0448\u0438\u043D " }), _jsxs("div", { children: [tireStoragePriceResponse.price, _jsx(FontAwesomeIcon, { icon: faRuble, style: { height: '0.9rem', paddingLeft: '5px' } })] })] })) : null, _jsxs("div", { className: 'row-price font-16-400-gray-dark', children: [_jsx("div", { children: " \u0412\u044B \u043D\u0430\u043A\u0430\u043F\u043B\u0438\u0432\u0430\u0435\u0442\u0435 " }), _jsx("div", { children: _jsx(Bonus, { bonus: Math.ceil(totalBonus) }) })] })] }), ((values === null || values === void 0 ? void 0 : values.tireRunflatOptionValue) === 'dontknow')
                && (_jsx(Row, { className: 'pt-1', children: _jsx(Col, { children: _jsx("div", { className: 'label pb-1 pt-1', children: "\u0421\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u043C\u043E\u0436\u0435\u0442 \u0431\u044B\u0442\u044C \u0443\u0432\u0435\u043B\u0438\u0447\u0435\u043D\u0430 \u043D\u0430 20%, \u0435\u0441\u043B\u0438 \u0443 \u0432\u0430\u0441 \u0440\u0435\u0437\u0438\u043D\u0430 RunFlat. \u0423\u0442\u043E\u0447\u043D\u044F\u0439\u0442\u0435 \u043F\u043E\u0434\u0440\u043E\u0431\u043D\u043E\u0441\u0442\u0438 \u0443 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u043E\u0432." }) }) }))] }));
};
export default PriceV2Component;
