import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Row, Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useEffect, useState, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import { useMediaQuery } from '@react-hooks-library/core';
import { stateAppActions } from '../../lib/mobx/stateAppStore';
import { PhoneInput } from '../core';
import { validateField } from '../core/components/validation';
import { fillingClientData } from './validation';
const PhonePanelContent = () => {
    const { watch, setValue, getValues } = useFormContext(); // retrieve all hook methods
    const values = watch();
    const [oldPhone, setOldPhone] = useState(null);
    // const params = new URLSearchParams(window.location.search);
    const [token, setToken] = useState('');
    const [visible, setVisible] = useState(false);
    const handleChallengeHidden = useCallback(() => setVisible(false), []);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const handleSave = () => {
        setValue('smsAlreadySend', false);
        stateAppActions.setAppStateOrder(5);
    };
    const handleClose = () => {
        setValue('phoneNumber', oldPhone);
        stateAppActions.setAppStateOrder(5);
    };
    useEffect(() => {
        setOldPhone(values === null || values === void 0 ? void 0 : values.phoneNumber);
    }, []);
    useEffect(() => {
        if (token) {
            setValue('captchaToken', token);
            handleSave();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);
    return (_jsxs("div", { className: 'modal-phone-form', children: [!isMobile && (_jsx(Row, { children: _jsxs(Col, { className: 'avtoapp_color_font_20', children: ["\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430", _jsx(FontAwesomeIcon, { icon: faXmark, className: 'pull-right font-20-500-middle-gray-dark header-button', onClick: handleClose })] }) })), _jsx(Row, { className: 'text1', children: _jsx(Col, { className: 'font-14-400-black-noSpacing', children: "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u0440, \u043D\u0430 \u043A\u043E\u0442\u043E\u0440\u044B\u0439 \u043C\u044B \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043C \u043A\u043E\u0434 \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F." }) }), _jsxs(Row, { children: [_jsxs("div", { className: 'sms-line sms-line-reverse', children: [_jsx("div", { children: _jsx(PhoneInput, { label: '\u0422\u0435\u043B\u0435\u0444\u043E\u043D', name: 'phoneNumber', 
                                    // iconType='phone'
                                    shouldValidate: true, validationSchema: fillingClientData }) }), _jsx("div", { className: 'repeat', children: _jsx(Button, { className: 'simple-button', variant: 'primary', onClick: () => {
                                        if (token) {
                                            handleSave();
                                        }
                                        else {
                                            setVisible(true);
                                        }
                                    }, disabled: oldPhone === (values === null || values === void 0 ? void 0 : values.phoneNumber) || validateField('phoneNumber', getValues(), fillingClientData), children: "\u0417\u0410\u041F\u0418\u0421\u0410\u0422\u042C\u0421\u042F" }) })] }), visible
                        && (_jsx(InvisibleSmartCaptcha, { sitekey: process.env.YANDEX_CAPTCHA_KEY, onSuccess: setToken, onChallengeHidden: handleChallengeHidden, visible: visible }))] })] }));
};
export default inject('calculatePriceStore')(observer(PhonePanelContent));
